@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");

.academics-layout-container {
  background-color: white;
  padding: 20px 40px;
  border-radius: 10px;

  margin-top: 80px;

  font-family: "Open Sans", sans-serif;

  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  .academics {
    margin-top: 20px;
    margin-bottom: 40px;

    h2 {
      margin: 0;
      color: #262626;
    }

    p {
      line-height: 1.5rem;
      color: #404040;
    }
  }

  .attendance {
    margin-bottom: 40px;

    h2 {
      color: #262626;
    }

    p {
      line-height: 1.5rem;
      color: #404040;
    }
  }

  .uniforms {
    margin-bottom: 40px;

    h2 {
      color: #262626;
    }

    p {
      line-height: 1.5rem;
      color: #404040;
    }
  }

  .school-hours {
    margin-bottom: 40px;
    width: 100%;

    h2 {
      color: #262626;
    }

    .winter-timing {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      table {
        width: 70%;
        padding: 0 20px;
        border: 1px solid #d1d5db;
        border-collapse: collapse;

        thead {
          tr {
            th {
              padding: 20px 0;
              border: 1px solid #d1d5db;
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 15px 0 15px 15px;
              border: 1px solid #d1d5db;
            }
          }
        }
      }
    }

    .summer-timing {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 40px 0;

      table {
        width: 70%;
        padding: 0 20px;
        border: 1px solid #d1d5db;
        border-collapse: collapse;

        thead {
          tr {
            th {
              padding: 20px 0;
              border: 1px solid #d1d5db;
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 15px 0 15px 15px;
              border: 1px solid #d1d5db;
            }
          }
        }
      }
    }

    .office-hours {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      table {
        width: 70%;
        padding: 0 20px;
        border: 1px solid #d1d5db;
        border-collapse: collapse;

        thead {
          tr {
            th {
              padding: 20px 0;
              border: 1px solid #d1d5db;
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 15px 0 15px 15px;
              border: 1px solid #d1d5db;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .academics-layout-container {
    .academics {
      h2 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
    }

    .attendance {
      h2 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
    }

    .uniforms {
      h2 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
    }

    .school-hours {
      h2 {
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .academics-layout-container {
    .academics {
      h2 {
        font-size: 22px;
      }

      p {
        font-size: 14px;
      }
    }

    .attendance {
      h2 {
        font-size: 22px;
      }

      p {
        font-size: 14px;
      }
    }

    .uniforms {
      h2 {
        font-size: 22px;
      }

      p {
        font-size: 14px;
      }
    }

    .school-hours {
      h2 {
        font-size: 22px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .academics-layout-container {
    width: 85%;
    padding: 20px;
    margin: 40px auto 40px auto;

    .academics {
      margin: 10px 0 20px 0;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }
    }

    .attendance {
      margin-bottom: 20px;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }
    }

    .uniforms {
      margin-bottom: 20px;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }
    }

    .school-hours {
      margin-bottom: 20px;
      width: 100%;

      h2 {
        font-size: 20px;
      }

      .winter-timing {
        table {
          width: 100%;

          padding: 0 10px;
          border: 1px solid #d1d5db;
          border-collapse: collapse;

          thead {
            tr {
              th {
                font-size: 16px;
                padding: 15px 0;
                border: 1px solid #d1d5db;
              }
            }
          }

          tbody {
            tr {
              td {
                font-size: 14px;
                padding: 15px 0 15px 15px;
                border: 1px solid #d1d5db;
              }
            }
          }
        }
      }

      .summer-timing {
        table {
          width: 100%;

          padding: 0 10px;
          border: 1px solid #d1d5db;
          border-collapse: collapse;

          thead {
            tr {
              th {
                font-size: 16px;
                padding: 15px 0;
                border: 1px solid #d1d5db;
              }
            }
          }

          tbody {
            tr {
              td {
                font-size: 14px;
                padding: 15px 0 15px 15px;
                border: 1px solid #d1d5db;
              }
            }
          }
        }
      }

      .office-hours {
        table {
          width: 100%;

          padding: 0 10px;
          border: 1px solid #d1d5db;
          border-collapse: collapse;

          thead {
            tr {
              th {
                font-size: 16px;
                padding: 15px 0;
                border: 1px solid #d1d5db;
              }
            }
          }

          tbody {
            tr {
              td {
                font-size: 14px;
                padding: 15px 0 15px 15px;
                border: 1px solid #d1d5db;
              }
            }
          }
        }
      }
    }
  }
}
