.home {
  width: 100%;

  margin-top: 10px;
  //margin-bottom: 100px;

  img {
    width: 100%;
  }
}
