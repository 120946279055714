.app {
  width: 60%;
  height: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
  .app {
    width: 80%;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .app {
    width: 80%;
  }
}

@media screen and (max-width: 768px) and (min-width: 674px) {
  .app {
    width: 90%;
  }
}

@media screen and (max-width: 674px) and (min-width: 425px) {
  .app {
    width: 95%;
  }
}

@media screen and (max-width: 425px) {
  .app {
    width: 100%;
  }
}
