@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");

.about-layout-container {
  background-color: white;

  padding: 20px 40px;

  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  border-radius: 10px;
  font-family: "Open Sans", sans-serif;

  .about-our-school {
    margin-bottom: 40px;
    margin-top: 30px;
    h2 {
      margin: 0;
      color: #262626;
    }

    p {
      // letter-spacing: 0.025rem;
      line-height: 1.5rem;
      color: #404040;
    }
  }

  .governing-body {
    margin-bottom: 40px;

    h2 {
      margin: 0;
      color: #262626;
    }

    p {
      // letter-spacing: 0.025rem;
      line-height: 1.5rem;
      color: #404040;
    }
  }

  .aims-and-objectives {
    margin-bottom: 40px;

    h2 {
      color: #262626;
    }

    ul {
      li {
        line-height: 1.5rem;
        margin-bottom: 5px;
        color: #404040;
      }
    }
  }

  .admission {
    margin-bottom: 40px;

    h2 {
      color: #262626;
    }

    p {
      line-height: 1.5rem;
      color: #404040;
    }
  }

  .attendance {
    margin-bottom: 20px;

    h2 {
      color: #262626;
    }

    p {
      line-height: 1.5rem;
      color: #404040;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .about-layout-container {
    .about-our-school {
      h2 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
    }

    .governing-body {
      h2 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
    }

    .aims-and-objectives {
      h2 {
        font-size: 24px;
      }

      ul {
        li {
          font-size: 16px;
        }
      }
    }

    .admission {
      h2 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
    }

    .attendance {
      h2 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .about-layout-container {
    .about-our-school {
      h2 {
        font-size: 22px;
      }

      p {
        font-size: 14px;
      }
    }

    .governing-body {
      h2 {
        font-size: 22px;
      }

      p {
        font-size: 14px;
      }
    }

    .aims-and-objectives {
      h2 {
        font-size: 22px;
      }

      ul {
        li {
          font-size: 14px;
        }
      }
    }

    .admission {
      h2 {
        font-size: 22px;
      }

      p {
        font-size: 14px;
      }
    }

    .attendance {
      h2 {
        font-size: 22px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .about-layout-container {
    padding: 20px;
    width: 85%;
    margin: 0 auto;

    .about-our-school {
      margin: 10px 0 20px 0;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }
    }

    .governing-body {
      margin-bottom: 20px;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }
    }

    .aims-and-objectives {
      margin-bottom: 20px;

      h2 {
        font-size: 20px;
      }

      ul {
        li {
          font-size: 14px;
        }
      }
    }

    .admission {
      margin-bottom: 20px;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }
    }

    .attendance {
      margin-bottom: 20px;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}
