.footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 60px;

  border-top: 1px solid #e2e8f0;

  bottom: 0;

  margin-top: 80px;

  background-color: #e2e8f0;

  .footer-copyright {
    color: #27272a;
    font-size: 14px;
  }
}
