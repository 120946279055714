.mobile-navbar-container {
  width: 100%;
  background: linear-gradient(to right, #0f2027, #203a43, #2c5364);
  position: relative;
  transition: all ease-in-out 500ms;

  .menu-icon {
    color: white;
    font-size: 20px;
    margin-left: 15px;
    padding: 10px;
    //transition: all ease-in-out 500ms;
  }

  .close-icon {
    color: white;
    font-size: 20px;
    margin-left: 15px;
    padding: 10px;
    //transition: all ease-in-out 500ms;
  }

  .mobile-navbar-visible {
    display: flex;
    flex-direction: column;
    width: 100%;
    //transition: all ease-in-out 500ms;

    position: absolute;
    z-index: 10;

    padding-bottom: 10px;
    background: linear-gradient(to right, #0f2027, #203a43, #2c5364);

    a {
      text-decoration: none;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 14px;
      font-weight: 600;

      padding: 10px 0;
      color: white;
    }
  }

  .mobile-navbar-hide {
    display: none;
    transition: all ease-in-out 500ms;
  }
}
