.faculties-layout-container {
  width: 100%;

  margin-top: 80px;

  background-color: white;

  border-radius: 10px;

  padding-bottom: 30px;

  .faculties-title {
    padding: 20px 20px 20px 40px;
  }

  .faculties-images-container {
    width: 100%;
    //padding: 20px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    //margin: auto;
    gap: 40px;

    .faculty-images-details {
      width: 300px;

      padding: 20px;

      margin: auto;

      .faculty-image {
        width: 100%;

        img {
          width: 100%;
          border-radius: 10px;
        }
      }

      .faculty-details {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        margin-top: 10px;
        height: 80px;

        h3 {
          margin: 0;
          text-align: center;
        }

        p {
          margin: 5px 0 0 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .faculties-layout-container {
    .faculties-images-container {
      width: 100%;

      display: grid;
      grid-template-columns: 1fr 1fr;

      gap: 30px;

      .faculty-images-details {
        width: 260px;
      }
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .faculties-layout-container {
    .faculties-images-container {
      width: 100%;

      display: grid;
      grid-template-columns: 1fr 1fr;

      gap: 30px;

      .faculty-images-details {
        width: 260px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .faculties-layout-container {
    width: 95%;
    margin: 80px auto 80px auto;

    // .faculties-title {

    // }

    .faculties-images-container {
      display: flex;
      align-items: center;
      justify-content: center;

      flex-direction: column;
    }
  }
}
