@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,300&family=Roboto&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Charm:wght@700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=MonteCarlo&display=swap");

.home-container {
  width: 100%;

  .home-body {
    width: 100%;
    display: flex;
    justify-content: space-between;

    margin-top: 80px;

    .home-detail {
      width: 60%;
      background-color: white;
      padding: 20px 40px;

      border-radius: 10px;

      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      font-family: "Roboto", sans-serif;

      h2 {
        font-size: 28px;
        font-weight: 800;
        letter-spacing: 0.05rem;
        color: #262626;
      }

      p {
        font-size: 16px;
        line-height: 1.5rem;
        font-weight: 500;
        letter-spacing: 0.025rem;
        color: #404040;
      }
    }

    .home-news {
      width: 30%;
      background-color: white;
      max-height: 552px;
      //padding: 20px 40px;

      border-radius: 10px;

      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      .news-title {
        padding: 40px 40px 10px 30px;

        h2 {
          padding: 0;
          margin: 0;

          color: #262626;

          font-size: 20px;
          letter-spacing: 0.025rem;

          font-weight: 600;
          font-family: "Roboto", sans-serif;
        }
      }

      .home-news-info {
        padding: 0 20px 20px 30px;
        overflow-y: scroll;
        height: 78%;
        margin-right: 10px;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px white;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: #d1d5db;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #94a3b8;
        }

        .news {
          display: flex;
          margin: 10px 0;

          p {
            padding: 0;
            margin: 0 0 0 8px;
            font-family: "Roboto", sans-serif;

            font-size: 14px;
            font-weight: 800;
            letter-spacing: 0.025rem;
            line-height: 1.25rem;

            color: #404040;

            a {
              text-decoration: none;
              color: inherit;
              cursor: pointer;
            }

            svg {
              font-size: 8px;
              margin-right: 8px;
              color: #52525b;
              margin-bottom: 0.5px;
            }

            span {
              padding: 2px 4px;

              background-color: #fef2f2;

              color: #dc2626;

              border: 1px solid #ef4444;

              font-size: 10px;
              font-weight: 800;
              border-radius: 4px;

              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .home-list {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    .home-thing-to-know {
      width: 45%;
      border-radius: 10px;

      padding: 20px;

      background-color: white;

      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      h3 {
        margin-left: 10px;
        font-family: "Roboto", sans-serif;
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 0.025rem;
        color: #262626;
      }

      ul {
        margin-right: 10px;

        li {
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          margin: 10px 0;
          font-weight: 500;
          letter-spacing: 0.025rem;

          color: #404040;

          list-style: square;
        }
      }
    }

    .home-key-feature {
      width: 45%;
      border-radius: 10px;

      padding: 20px;
      background-color: white;

      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      h3 {
        margin-left: 10px;
        font-family: "Roboto", sans-serif;
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 0.025rem;
        color: #262626;
      }

      ul {
        margin-right: 10px;

        li {
          font-family: "Roboto", sans-serif;
          font-size: 16px;

          color: #404040;

          margin: 10px 0;
        }
      }
    }
  }

  .home-pri-container {
    display: flex;

    justify-content: space-between;

    margin-top: 40px;

    .princ-image-container {
      background-color: white;
      padding: 40px;
      border-radius: 10px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      .princ-img {
        width: 300px;
        height: 300px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;

          border-radius: 50%;
        }
      }

      .princ-details {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .principal-name {
          margin: 0;
          text-align: center;
          font-size: 26px;
          // font-family: "Pinyon Script", cursive;
          // font-family: "Merriweather", serif;
          font-family: "Charm", cursive;
          color: #262626;
        }

        .principal-title {
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          margin: 5px 0;
          color: #262626;
          font-family: "Charm", cursive;
        }

        .principal-school {
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          //margin-top: 5px;
          font-family: "Charm", cursive;
          color: #262626;
        }
      }
    }

    .princ-quote {
      background-color: white;
      padding: 40px;

      border-radius: 10px;

      width: 60%;

      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      color: #262626;

      //height: 100%;

      margin-left: 30px;

      // font-family: "Roboto", sans-serif;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        margin: 0;
        font-size: 32px;
        font-family: "Dancing Script", cursive;
      }

      .quote-1,
      .quote-2,
      .quote-3 {
        font-family: "Dancing Script", cursive;

        margin: 0;
      }

      .quote-1 {
        font-size: 24px;
        margin-top: 30px;

        span {
          margin-right: 50px;
        }
      }

      .quote-2 {
        font-size: 24px;
        margin-top: 10px;

        span {
          margin-right: 50px;
        }
      }

      .quote-3 {
        font-size: 28px;
        margin-top: 40px;
      }
    }
  }
}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
  .home-container {
    width: 100%;

    .home-body {
      width: 100%;
      display: flex;
      justify-content: space-between;

      margin-top: 80px;

      .home-detail {
        width: 60%;
        background-color: white;
        padding: 20px 40px;

        border-radius: 10px;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        font-family: "Roboto", sans-serif;

        h2 {
          font-size: 28px;
          font-weight: 800;
          letter-spacing: 0.05rem;
          color: #262626;
        }

        p {
          font-size: 16px;
          line-height: 1.5rem;
          font-weight: 500;
          letter-spacing: 0.025rem;
          color: #404040;
        }
      }

      .home-news {
        width: 30%;
        background-color: white;
        // max-height: 552px;
        max-height: 100%;
        //padding: 20px 40px;

        border-radius: 10px;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        .news-title {
          padding: 40px 40px 10px 30px;

          h2 {
            padding: 0;
            margin: 0;

            color: #262626;

            font-size: 20px;
            letter-spacing: 0.025rem;

            font-weight: 600;
            font-family: "Roboto", sans-serif;
          }
        }

        .home-news-info {
          padding: 0 20px 20px 30px;
          overflow-y: scroll;
          height: 78%;
          margin-right: 10px;

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px white;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: #d1d5db;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #94a3b8;
          }

          .news {
            display: flex;
            margin: 10px 0;

            p {
              padding: 0;
              margin: 0 0 0 8px;
              font-family: "Roboto", sans-serif;

              font-size: 14px;
              font-weight: 800;
              letter-spacing: 0.025rem;
              line-height: 1.25rem;

              color: #404040;

              svg {
                font-size: 8px;
                margin-right: 8px;
                color: #52525b;
                margin-bottom: 0.5px;
              }

              span {
                padding: 2px 4px;

                background-color: #fef2f2;

                color: #dc2626;

                border: 1px solid #ef4444;

                font-size: 10px;
                font-weight: 800;
                border-radius: 4px;

                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .home-list {
      width: 100%;
      margin-top: 40px;
      display: flex;
      justify-content: space-between;

      .home-thing-to-know {
        width: 45%;
        border-radius: 10px;

        padding: 20px;

        background-color: white;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        h3 {
          margin-left: 10px;
          font-family: "Roboto", sans-serif;
          font-size: 22px;
          font-weight: 700;
          letter-spacing: 0.025rem;
          color: #262626;
        }

        ul {
          margin-right: 10px;

          li {
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            margin: 10px 0;
            font-weight: 500;
            letter-spacing: 0.025rem;

            color: #404040;

            list-style: square;
          }
        }
      }

      .home-key-feature {
        width: 45%;
        border-radius: 10px;

        padding: 20px;
        background-color: white;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        h3 {
          margin-left: 10px;
          font-family: "Roboto", sans-serif;
          font-size: 22px;
          font-weight: 700;
          letter-spacing: 0.025rem;
          color: #262626;
        }

        ul {
          margin-right: 10px;

          li {
            font-family: "Roboto", sans-serif;
            font-size: 16px;

            color: #404040;

            margin: 10px 0;
          }
        }
      }
    }

    .home-pri-container {
      display: flex;

      justify-content: space-between;

      margin-top: 40px;

      .princ-image-container {
        background-color: white;
        padding: 40px;
        border-radius: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        .princ-img {
          width: 300px;
          height: 300px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            border-radius: 50%;
          }
        }

        .princ-details {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .principal-name {
            margin: 0;
            text-align: center;
            font-size: 26px;
            // font-family: "Pinyon Script", cursive;
            // font-family: "Merriweather", serif;
            font-family: "Charm", cursive;
            color: #262626;
          }

          .principal-title {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            margin: 5px 0;
            color: #262626;
            font-family: "Charm", cursive;
          }

          .principal-school {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            //margin-top: 5px;
            font-family: "Charm", cursive;
            color: #262626;
          }
        }
      }

      .princ-quote {
        background-color: white;
        padding: 40px;

        border-radius: 10px;

        width: 60%;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        color: #262626;

        //height: 100%;

        margin-left: 30px;

        // font-family: "Roboto", sans-serif;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
          margin: 0;
          font-size: 32px;
          font-family: "Dancing Script", cursive;
        }

        .quote-1,
        .quote-2,
        .quote-3 {
          font-family: "Dancing Script", cursive;

          margin: 0;
        }

        .quote-1 {
          font-size: 24px;
          margin-top: 30px;

          span {
            margin-right: 50px;
          }
        }

        .quote-2 {
          font-size: 24px;
          margin-top: 10px;

          span {
            margin-right: 50px;
          }
        }

        .quote-3 {
          font-size: 28px;
          margin-top: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .home-container {
    width: 100%;

    .home-body {
      width: 100%;

      .home-detail {
        width: 59%;
        background-color: white;
        padding: 15px 30px;

        border-radius: 10px;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        font-family: "Roboto", sans-serif;

        h2 {
          font-size: 20px;
          font-weight: 800;
          letter-spacing: 0.05rem;
          color: #262626;
        }

        p {
          font-size: 14px;
          line-height: 1.5rem;
          font-weight: 500;
          letter-spacing: 0.025rem;
          color: #404040;
        }
      }

      .home-news {
        width: 30%;
        background-color: white;
        // max-height: 552px;
        max-height: 100%;
        //padding: 20px 40px;

        border-radius: 10px;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        .news-title {
          padding: 30px 30px 10px 30px;

          h2 {
            padding: 0;
            margin: 0;

            color: #262626;

            font-size: 20px;
            letter-spacing: 0.025rem;

            font-weight: 600;
            font-family: "Roboto", sans-serif;
          }
        }

        .home-news-info {
          padding: 0 15px 20px 30px;
          overflow-y: scroll;
          height: 78%;
          margin-right: 10px;

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px white;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: #d1d5db;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #94a3b8;
          }

          .news {
            display: flex;
            margin: 10px 0;

            p {
              padding: 0;
              margin: 0 0 0 8px;
              font-family: "Roboto", sans-serif;

              font-size: 12px;
              font-weight: 800;
              letter-spacing: 0.025rem;
              line-height: 1.25rem;

              color: #404040;

              svg {
                font-size: 6px;
                margin-right: 8px;
                color: #52525b;
                margin-bottom: 0.5px;
              }

              span {
                padding: 2px 4px;

                background-color: #fef2f2;

                color: #dc2626;

                border: 1px solid #ef4444;

                font-size: 10px;
                font-weight: 800;
                border-radius: 4px;

                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .home-list {
      width: 100%;
      margin-top: 40px;
      display: flex;
      justify-content: space-between;

      .home-thing-to-know {
        width: 43%;
        border-radius: 10px;

        padding: 20px;

        background-color: white;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        h3 {
          margin-left: 10px;
          font-family: "Roboto", sans-serif;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0.025rem;
          color: #262626;
        }

        ul {
          margin-right: 10px;

          li {
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            margin: 10px 0;
            font-weight: 500;
            letter-spacing: 0.025rem;

            color: #404040;

            list-style: square;
          }
        }
      }

      .home-key-feature {
        width: 43%;
        border-radius: 10px;

        padding: 20px;
        background-color: white;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        h3 {
          margin-left: 10px;
          font-family: "Roboto", sans-serif;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0.025rem;
          color: #262626;
        }

        ul {
          margin-right: 10px;

          li {
            font-family: "Roboto", sans-serif;
            font-size: 14px;

            color: #404040;

            margin: 10px 0;
          }
        }
      }
    }

    .home-pri-container {
      display: flex;

      justify-content: space-between;

      margin-top: 40px;

      width: 100%;

      .princ-image-container {
        background-color: white;
        padding: 30px;
        border-radius: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        width: 30%;
        display: flex;
        align-items: center;
        // justify-content: center;
        flex-direction: column;

        .princ-img {
          width: 200px;
          height: 200px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            border-radius: 50%;
          }
        }

        .princ-details {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .principal-name {
            margin: 0;
            text-align: center;
            font-size: 22px;
            // font-family: "Pinyon Script", cursive;
            // font-family: "Merriweather", serif;
            font-family: "Charm", cursive;
            color: #262626;
          }

          .principal-title {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            margin: 5px 0;
            color: #262626;
            font-family: "Charm", cursive;
          }

          .principal-school {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            //margin-top: 5px;
            font-family: "Charm", cursive;
            color: #262626;
          }
        }
      }

      .princ-quote {
        background-color: white;
        padding: 30px;

        border-radius: 10px;

        width: 60%;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        color: #262626;

        //height: 100%;

        margin-left: 30px;

        // font-family: "Roboto", sans-serif;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
          margin: 0;
          font-size: 28px;
          font-family: "Dancing Script", cursive;
        }

        .quote-1,
        .quote-2,
        .quote-3 {
          font-family: "Dancing Script", cursive;

          margin: 0;
        }

        .quote-1 {
          font-size: 22px;
          margin-top: 30px;

          span {
            margin-right: 50px;
          }
        }

        .quote-2 {
          font-size: 22px;
          margin-top: 10px;

          span {
            margin-right: 50px;
          }
        }

        .quote-3 {
          font-size: 24px;
          margin-top: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 674px) {
  .home-container {
    width: 100%;

    .home-body {
      width: 100%;

      .home-detail {
        width: 59%;
        background-color: white;
        padding: 15px 30px;

        border-radius: 10px;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        font-family: "Roboto", sans-serif;

        h2 {
          font-size: 20px;
          font-weight: 800;
          letter-spacing: 0.05rem;
          color: #262626;
        }

        p {
          font-size: 14px;
          line-height: 1.5rem;
          font-weight: 500;
          letter-spacing: 0.025rem;
          color: #404040;
        }
      }

      .home-news {
        width: 30%;
        background-color: white;
        // max-height: 552px;
        max-height: 100%;
        //padding: 20px 40px;

        border-radius: 10px;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        .news-title {
          padding: 30px 30px 10px 30px;

          h2 {
            padding: 0;
            margin: 0;

            color: #262626;

            font-size: 20px;
            letter-spacing: 0.025rem;

            font-weight: 600;
            font-family: "Roboto", sans-serif;
          }
        }

        .home-news-info {
          padding: 0 15px 20px 30px;
          overflow-y: scroll;
          height: 78%;
          margin-right: 10px;

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px white;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: #d1d5db;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #94a3b8;
          }

          .news {
            display: flex;
            margin: 10px 0;

            p {
              padding: 0;
              margin: 0 0 0 8px;
              font-family: "Roboto", sans-serif;

              font-size: 12px;
              font-weight: 800;
              letter-spacing: 0.025rem;
              line-height: 1.25rem;

              color: #404040;

              svg {
                font-size: 6px;
                margin-right: 8px;
                color: #52525b;
                margin-bottom: 0.5px;
              }

              span {
                padding: 2px 4px;

                background-color: #fef2f2;

                color: #dc2626;

                border: 1px solid #ef4444;

                font-size: 10px;
                font-weight: 800;
                border-radius: 4px;

                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .home-list {
      width: 100%;
      margin-top: 40px;
      display: flex;
      justify-content: space-between;

      .home-thing-to-know {
        width: 43%;
        border-radius: 10px;

        padding: 20px;

        background-color: white;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        h3 {
          margin-left: 10px;
          font-family: "Roboto", sans-serif;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0.025rem;
          color: #262626;
        }

        ul {
          margin-right: 10px;

          li {
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            margin: 10px 0;
            font-weight: 500;
            letter-spacing: 0.025rem;

            color: #404040;

            list-style: square;
          }
        }
      }

      .home-key-feature {
        width: 43%;
        border-radius: 10px;

        padding: 20px;
        background-color: white;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        h3 {
          margin-left: 10px;
          font-family: "Roboto", sans-serif;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0.025rem;
          color: #262626;
        }

        ul {
          margin-right: 10px;

          li {
            font-family: "Roboto", sans-serif;
            font-size: 14px;

            color: #404040;

            margin: 10px 0;
          }
        }
      }
    }

    .home-pri-container {
      display: flex;

      justify-content: space-between;

      margin-top: 40px;

      width: 100%;

      .princ-image-container {
        background-color: white;
        padding: 30px;
        border-radius: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        width: 25%;

        display: flex;
        align-items: center;
        // justify-content: center;
        flex-direction: column;

        .princ-img {
          width: 200px;
          height: 200px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            border-radius: 50%;
          }
        }

        .princ-details {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .principal-name {
            margin: 0;
            text-align: center;
            font-size: 22px;
            // font-family: "Pinyon Script", cursive;
            // font-family: "Merriweather", serif;
            font-family: "Charm", cursive;
            color: #262626;
          }

          .principal-title {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            margin: 5px 0;
            color: #262626;
            font-family: "Charm", cursive;
          }

          .principal-school {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            //margin-top: 5px;
            font-family: "Charm", cursive;
            color: #262626;
          }
        }
      }

      .princ-quote {
        background-color: white;
        padding: 30px;

        border-radius: 10px;

        width: 55%;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        color: #262626;

        //height: 100%;

        margin-left: 0;

        // font-family: "Roboto", sans-serif;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
          margin: 0;
          font-size: 22px;
          font-family: "Dancing Script", cursive;
        }

        .quote-1,
        .quote-2,
        .quote-3 {
          font-family: "Dancing Script", cursive;

          margin: 0;
        }

        .quote-1 {
          font-size: 20px;
          margin-top: 30px;

          span {
            margin-right: 50px;
          }
        }

        .quote-2 {
          font-size: 22px;
          margin-top: 10px;

          span {
            margin-right: 50px;
          }
        }

        .quote-3 {
          font-size: 22px;
          margin-top: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 674px) and (min-width: 425px) {
  .home-container {
    width: 100%;

    .home-body {
      width: 100%;

      display: flex;
      flex-direction: column;

      .home-detail {
        width: 90%;
        background-color: white;
        padding: 15px 30px;

        border-radius: 10px;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        font-family: "Roboto", sans-serif;

        h2 {
          font-size: 20px;
          font-weight: 800;
          letter-spacing: 0.05rem;
          color: #262626;
        }

        p {
          font-size: 14px;
          line-height: 1.5rem;
          font-weight: 500;
          letter-spacing: 0.025rem;
          color: #404040;
        }
      }

      .home-news {
        width: 100%;
        background-color: white;
        // max-height: 552px;
        // max-height: 100%;
        //padding: 20px 40px;

        margin-top: 30px;

        border-radius: 10px;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        .news-title {
          padding: 30px 30px 10px 30px;

          h2 {
            padding: 0;
            margin: 0;

            color: #262626;

            font-size: 20px;
            letter-spacing: 0.025rem;

            font-weight: 600;
            font-family: "Roboto", sans-serif;
          }
        }

        .home-news-info {
          padding: 0 15px 20px 30px;
          overflow-y: scroll;
          height: 78%;
          margin-right: 10px;

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px white;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: #d1d5db;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #94a3b8;
          }

          .news {
            display: flex;
            margin: 10px 0;

            p {
              padding: 0;
              margin: 0 0 0 8px;
              font-family: "Roboto", sans-serif;

              font-size: 12px;
              font-weight: 800;
              letter-spacing: 0.025rem;
              line-height: 1.25rem;

              color: #404040;

              svg {
                font-size: 6px;
                margin-right: 8px;
                color: #52525b;
                margin-bottom: 0.5px;
              }

              span {
                padding: 2px 4px;

                background-color: #fef2f2;

                color: #dc2626;

                border: 1px solid #ef4444;

                font-size: 10px;
                font-weight: 800;
                border-radius: 4px;

                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .home-list {
      width: 100%;
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .home-thing-to-know {
        width: 93%;
        border-radius: 10px;

        padding: 20px;

        background-color: white;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        h3 {
          margin-left: 10px;
          font-family: "Roboto", sans-serif;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0.025rem;
          color: #262626;
        }

        ul {
          margin-right: 10px;

          li {
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            margin: 10px 0;
            font-weight: 500;
            letter-spacing: 0.025rem;

            color: #404040;

            list-style: square;
          }
        }
      }

      .home-key-feature {
        width: 93%;
        border-radius: 10px;

        margin-top: 30px;

        padding: 20px;
        background-color: white;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        h3 {
          margin-left: 10px;
          font-family: "Roboto", sans-serif;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0.025rem;
          color: #262626;
        }

        ul {
          margin-right: 10px;

          li {
            font-family: "Roboto", sans-serif;
            font-size: 14px;

            color: #404040;

            margin: 10px 0;
          }
        }
      }
    }

    .home-pri-container {
      display: flex;

      justify-content: space-between;

      margin-top: 40px;

      width: 100%;

      .princ-image-container {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        width: 25%;

        display: flex;
        align-items: center;
        // justify-content: center;
        flex-direction: column;

        .princ-img {
          width: 140px;
          height: 140px;

          margin-top: 20px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            border-radius: 50%;
          }
        }

        .princ-details {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .principal-name {
            margin: 0;
            text-align: center;
            font-size: 16px;
            // font-family: "Pinyon Script", cursive;
            // font-family: "Merriweather", serif;
            font-family: "Charm", cursive;
            color: #262626;
          }

          .principal-title {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            margin: 5px 0;
            color: #262626;
            font-family: "Charm", cursive;
          }

          .principal-school {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            //margin-top: 5px;
            font-family: "Charm", cursive;
            color: #262626;
          }
        }
      }

      .princ-quote {
        background-color: white;
        padding: 30px;

        border-radius: 10px;

        width: 55%;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        color: #262626;

        //height: 100%;

        margin-left: 0;

        // font-family: "Roboto", sans-serif;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
          margin: 0;
          font-size: 20px;
          font-family: "Dancing Script", cursive;
        }

        .quote-1,
        .quote-2,
        .quote-3 {
          font-family: "Dancing Script", cursive;

          margin: 0;
        }

        .quote-1 {
          font-size: 18px;
          margin-top: 30px;

          span {
            margin-right: 50px;
          }
        }

        .quote-2 {
          font-size: 20px;
          margin-top: 10px;

          span {
            margin-right: 50px;
          }
        }

        .quote-3 {
          font-size: 22px;
          margin-top: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .home-container {
    width: 95%;
    margin: 0 auto;

    .home-body {
      width: 100%;

      display: flex;
      flex-direction: column;

      .home-detail {
        width: 85%;
        background-color: white;
        padding: 15px 30px;

        border-radius: 10px;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        font-family: "Roboto", sans-serif;

        h2 {
          font-size: 20px;
          font-weight: 800;
          letter-spacing: 0.05rem;
          color: #262626;
        }

        p {
          font-size: 14px;
          line-height: 1.5rem;
          font-weight: 500;
          letter-spacing: 0.025rem;
          color: #404040;
        }
      }

      .home-news {
        width: 100%;
        background-color: white;
        // max-height: 552px;
        // max-height: 100%;
        //padding: 20px 40px;

        margin-top: 30px;

        border-radius: 10px;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        .news-title {
          padding: 30px 30px 10px 30px;

          h2 {
            padding: 0;
            margin: 0;

            color: #262626;

            font-size: 20px;
            letter-spacing: 0.025rem;

            font-weight: 600;
            font-family: "Roboto", sans-serif;
          }
        }

        .home-news-info {
          padding: 0 15px 20px 30px;
          overflow-y: scroll;
          height: 78%;
          margin-right: 10px;

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px white;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: #d1d5db;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #94a3b8;
          }

          .news {
            display: flex;
            margin: 10px 0;

            p {
              padding: 0;
              margin: 0 0 0 8px;
              font-family: "Roboto", sans-serif;

              font-size: 12px;
              font-weight: 800;
              letter-spacing: 0.025rem;
              line-height: 1.25rem;

              color: #404040;

              svg {
                font-size: 6px;
                margin-right: 8px;
                color: #52525b;
                margin-bottom: 0.5px;
              }

              span {
                padding: 2px 4px;

                background-color: #fef2f2;

                color: #dc2626;

                border: 1px solid #ef4444;

                font-size: 10px;
                font-weight: 800;
                border-radius: 4px;

                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .home-list {
      width: 100%;
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .home-thing-to-know {
        width: 90%;
        border-radius: 10px;

        padding: 20px;

        background-color: white;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        h3 {
          margin-left: 10px;
          font-family: "Roboto", sans-serif;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0.025rem;
          color: #262626;
        }

        ul {
          margin-right: 10px;

          li {
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            margin: 10px 0;
            font-weight: 500;
            letter-spacing: 0.025rem;

            color: #404040;

            list-style: square;
          }
        }
      }

      .home-key-feature {
        width: 90%;
        border-radius: 10px;

        margin-top: 30px;

        padding: 20px;
        background-color: white;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        h3 {
          margin-left: 10px;
          font-family: "Roboto", sans-serif;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0.025rem;
          color: #262626;
        }

        ul {
          margin-right: 10px;

          li {
            font-family: "Roboto", sans-serif;
            font-size: 14px;

            color: #404040;

            margin: 10px 0;
          }
        }
      }
    }

    .home-pri-container {
      display: flex;

      justify-content: space-between;

      flex-direction: column;

      margin-top: 40px;

      width: 100%;

      .princ-image-container {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        width: 90%;

        display: flex;
        align-items: center;
        // justify-content: center;
        flex-direction: column;

        .princ-img {
          width: 180px;
          height: 180px;

          margin-top: 20px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            border-radius: 50%;
          }
        }

        .princ-details {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .principal-name {
            margin: 0;
            text-align: center;
            font-size: 16px;
            // font-family: "Pinyon Script", cursive;
            // font-family: "Merriweather", serif;
            font-family: "Charm", cursive;
            color: #262626;
          }

          .principal-title {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            margin: 5px 0;
            color: #262626;
            font-family: "Charm", cursive;
          }

          .principal-school {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            //margin-top: 5px;
            font-family: "Charm", cursive;
            color: #262626;
          }
        }
      }

      .princ-quote {
        background-color: white;
        padding: 30px;

        border-radius: 10px;

        margin-top: 40px;

        width: 85%;

        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        color: #262626;

        //height: 100%;

        margin-left: 0;

        // font-family: "Roboto", sans-serif;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
          margin: 0;
          font-size: 20px;
          font-family: "Dancing Script", cursive;
        }

        .quote-1,
        .quote-2,
        .quote-3 {
          font-family: "Dancing Script", cursive;

          margin: 0;
        }

        .quote-1 {
          font-size: 18px;
          margin-top: 30px;

          span {
            margin-right: 50px;
          }
        }

        .quote-2 {
          font-size: 20px;
          margin-top: 10px;

          span {
            margin-right: 50px;
          }
        }

        .quote-3 {
          font-size: 22px;
          margin-top: 40px;
        }
      }
    }
  }
}
