.navbar-container {
  width: 100%;
  margin-top: 20px;

  .desktop-navbar {
    display: flex;

    height: 60px;

    align-items: center;
    justify-content: space-around;

    background: linear-gradient(to right, #0f2027, #203a43, #2c5364);
  }

  a {
    text-decoration: none;
    height: 100%;
    width: 110px;

    // border-bottom: 4px solid transparent;

    &:hover {
      // border-bottom: 4px solid white;
      // background-color: #334155;
      background-color: #2c5364;
      // background-color: #475569;
    }

    .navbar-links {
      //margin-right: 40px;
      height: 100%;
      //width: 100%;
      padding: 0 15px;

      color: white;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.025rem;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.active {
  text-decoration: none;
  height: 100%;
  width: 110px;

  // background-color: #334155;
  background-color: #2c5364;

  .navbar-links {
    //margin-right: 40px;
    height: 100%;
    //width: 100%;
    padding: 0 15px;

    color: white;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.025rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 425px) {
  .navbar-container {
    height: 50px;

    a {
      .navbar-links {
        padding: 0 10px;
        font-size: 12px;
      }
    }
  }
}
