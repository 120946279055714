.slick-slider-custom-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  .custom-dots {
    width: 10px;
    height: 10px;
    // border-radius: 5px;
    border-radius: 50%;

    // background-color: #e5e5e5;
    // background-color: #737373;
    // background-color: white;
    background-color: #525252;
    opacity: 0.7;
    transition: all ease-in-out 300ms;
  }
}

.slick-dots {
  position: absolute;
  top: 93%;

  .slick-active {
    .slick-slider-custom-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      .custom-dots {
        width: 20px;
        // width: 7px;
        height: 10px;
        border-radius: 5px;
        // border-radius: 50%;
        background-color: #f8fafc;
        opacity: 1;
        transition: all ease-in-out 150ms;
      }
    }
  }
}

.image-slider {
  margin-top: 80px;

  .image-container {
    img {
      border-radius: 10px;
      //box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
  }
}

@media screen and (max-width: 425px) {
  .slick-slider-custom-dots {
    .custom-dots {
      width: 5px;
      height: 5px;
    }
  }

  .slick-dots {
    top: 85%;

    .slick-active {
      .slick-slider-custom-dots {
        display: flex;
        align-items: center;
        justify-content: center;
        .custom-dots {
          width: 10px;
          // width: 7px;
          height: 5px;
          // border-radius: 5px;
          // border-radius: 50%;
          background-color: #f8fafc;
          opacity: 1;
          transition: all ease-in-out 150ms;
        }
      }
    }
  }
}
