.school-logo-container {
  width: 100%;

  margin-top: 20px;
  //padding-top: 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 100%;
    object-fit: contain;
  }
}
