.about-main-container {
  width: 100%;

  margin-top: 80px;
  .about-school-cover {
    width: 100%;

    margin-bottom: 40px;

    img {
      width: 100%;
      height: 620px;
      object-fit: cover;

      border-radius: 10px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .about-main-container {
    .about-school-cover {
      img {
        height: 420px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .about-main-container {
    margin-top: 40px;
    .about-school-cover {
      width: 95%;
      margin: 0 auto 40px auto;

      img {
        height: 240px;
      }
    }
  }
}
