.gallery-layout-container {
  width: 100%;

  margin-top: 80px;
  // border-radius: 10px;

  background-color: white;
  border-radius: 10px;

  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  .gallery-title {
    h2 {
      padding: 30px 0 0 30px;
      font-size: 30px;
      font-weight: 700;
    }
  }

  .gallery-image-container {
    width: 100%;

    padding: 20px 0 40px 0;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px 20px;

    .gallery-image {
      width: 500px;
      margin: auto;

      img {
        width: 100%;
        border-radius: 10px;
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .gallery-layout-container {
    width: 100%;
    margin: 40px auto 40px auto;

    .gallery-title {
      h2 {
        font-size: 28px;
      }
    }

    .gallery-image-container {
      width: 100%;

      padding: 20px 0 40px 0;

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 50px 20px;

      .gallery-image {
        width: 340px;
        margin: auto;

        img {
          width: 100%;
          border-radius: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .gallery-layout-container {
    width: 100%;
    margin: 40px auto 40px auto;

    .gallery-title {
      h2 {
        font-size: 24px;
      }
    }

    .gallery-image-container {
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .gallery-image {
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .gallery-layout-container {
    width: 95%;
    margin: 40px auto 40px auto;

    .gallery-title {
      h2 {
        font-size: 20px;
      }
    }

    .gallery-image-container {
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .gallery-image {
        width: 90%;
      }
    }
  }
}
