@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");

.contact-us-layout-container {
  width: 100%;

  margin-top: 80px;

  .contact-us-row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .contact-address {
      width: 40%;
      background-color: white;
      padding: 40px;
      border-radius: 10px;

      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      .contact-info {
        width: 100%;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 30px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .email,
      .telephone,
      .mobile {
        font-size: 18px;
        margin: 10px 0;
        letter-spacing: 0.025rem;
        font-weight: 400;

        span {
          font-weight: 700;
        }
      }

      .school-address {
        margin-top: 8px;

        .address,
        .state-address,
        .pin-code {
          font-size: 18px;
          margin: 10px 0;
          letter-spacing: 0.025rem;
          font-weight: 400;

          span {
            font-weight: 700;
          }
        }
      }
    }

    .contact-form-container {
      width: 40%;
      background-color: white;
      padding: 40px;
      border-radius: 10px;

      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      .contact-us-form-title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 25px;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .form-area-inputs-fields {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .form-container {
          width: 80%;

          .form-names-input,
          .form-email,
          .form-message {
            display: flex;
            flex-direction: column;
            margin: 20px 0;
            width: 100%;

            label {
              margin-bottom: 5px;
            }

            input {
              height: 30px;
              padding: 5px 10px;
              border-radius: 8px;
              letter-spacing: 0.025rem;

              border: 1px solid #94a3b8;

              color: #1f2937;

              font-size: 14px;
              font-family: "Open Sans", sans-serif;

              &:focus {
                border: 1px solid #22d3ee;
                outline: 3px solid #cffafe;
              }
            }

            textarea {
              border: 1px solid #94a3b8;
              border-radius: 10px;
              padding: 8px;
              letter-spacing: 0.025rem;

              font-size: 14px;
              font-family: "Open Sans", sans-serif;

              color: #1f2937;

              &:focus {
                border: 1px solid #22d3ee;
                outline: 3px solid #cffafe;
              }
            }
          }

          .form-btn {
            width: 100%;
            margin-top: 30px;

            input {
              width: 100%;
              height: 40px;
              border-radius: 8px;
              border: none;
              font-size: 14px;
              font-weight: 700;
              letter-spacing: 0.025rem;
              background-color: #3b82f6;

              color: white;

              cursor: pointer;

              &:hover {
                background-color: #2563eb;
              }
            }
          }
        }
      }
    }
  }

  .google-map {
    width: 100%;
    margin-top: 60px;
    background-color: white;
    border-radius: 10px;

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .school-map-title {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      h2 {
        font-size: 28px;
        padding: 20px 0 0 0;
      }
    }

    .school-google-map {
      width: 100%;
      display: flex;
      justify-content: center;

      padding: 20px 0 40px 0;

      iframe {
        width: 70%;
        height: 500px;

        border: none;
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .contact-us-layout-container {
    .contact-us-row {
      .contact-address {
        width: 40%;
        padding: 30px;

        .contact-info {
          font-size: 22px;
        }

        .email,
        .telephone,
        .mobile {
          font-size: 14px;
          margin: 10px 0;
          letter-spacing: 0.025rem;
          font-weight: 400;

          span {
            font-weight: 700;
          }
        }

        .school-address {
          .address,
          .state-address,
          .pin-code {
            font-size: 14px;
            margin: 10px 0;
            letter-spacing: 0.025rem;
            font-weight: 400;

            span {
              font-weight: 700;
            }
          }
        }
      }

      .contact-form-container {
        width: 40%;
        padding: 30px;

        .contact-us-form-title {
          font-size: 22px;
        }

        .form-area-inputs-fields {
          .form-container {
            width: 90%;

            .form-names-input,
            .form-email,
            .form-message {
              display: flex;
              flex-direction: column;
              margin: 20px 0;
              width: 100%;

              label {
                margin-bottom: 5px;
                font-size: 16px;
                font-weight: 600;
              }

              input {
                height: 30px;
                padding: 5px 10px;
                border-radius: 8px;
                letter-spacing: 0.025rem;

                border: 1px solid #94a3b8;

                color: #1f2937;

                font-size: 14px;
                font-family: "Open Sans", sans-serif;

                &:focus {
                  border: 1px solid #22d3ee;
                  outline: 3px solid #cffafe;
                }
              }

              textarea {
                border: 1px solid #94a3b8;
                border-radius: 10px;
                padding: 8px;
                letter-spacing: 0.025rem;

                font-size: 14px;
                font-family: "Open Sans", sans-serif;

                color: #1f2937;

                &:focus {
                  border: 1px solid #22d3ee;
                  outline: 3px solid #cffafe;
                }
              }
            }

            .form-btn {
              width: 100%;
              margin-top: 30px;

              input {
                width: 100%;
                height: 40px;
                border-radius: 8px;
                border: none;
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 0.025rem;
                background-color: #3b82f6;

                color: white;

                cursor: pointer;

                &:hover {
                  background-color: #2563eb;
                }
              }
            }
          }
        }
      }
    }

    .google-map {
      width: 100%;
      margin-top: 60px;
      background-color: white;
      border-radius: 10px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      .school-map-title {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
          font-size: 22px;
          padding: 20px 0 0 0;
        }
      }

      .school-google-map {
        width: 100%;
        display: flex;
        justify-content: center;

        padding: 10px 0 40px 0;

        iframe {
          width: 70%;
          height: 400px;

          border: none;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .contact-us-layout-container {
    .contact-us-row {
      .contact-address {
        width: 37%;
        padding: 30px;

        .contact-info {
          font-size: 20px;
        }

        .email,
        .telephone,
        .mobile {
          font-size: 14px;
          margin: 10px 0;
          letter-spacing: 0.025rem;
          font-weight: 400;

          span {
            font-weight: 700;
          }
        }

        .school-address {
          .address,
          .state-address,
          .pin-code {
            font-size: 14px;
            margin: 10px 0;
            letter-spacing: 0.025rem;
            font-weight: 400;

            span {
              font-weight: 700;
            }
          }
        }
      }

      .contact-form-container {
        width: 40%;
        padding: 30px;

        .contact-us-form-title {
          font-size: 22px;
        }

        .form-area-inputs-fields {
          .form-container {
            width: 90%;

            .form-names-input,
            .form-email,
            .form-message {
              display: flex;
              flex-direction: column;
              margin: 20px 0;
              width: 100%;

              label {
                margin-bottom: 5px;
              }

              input {
                height: 25px;
                padding: 5px 10px;
                border-radius: 8px;
                letter-spacing: 0.025rem;

                border: 1px solid #94a3b8;

                color: #1f2937;

                font-size: 14px;
                font-family: "Open Sans", sans-serif;

                &:focus {
                  border: 1px solid #22d3ee;
                  outline: 3px solid #cffafe;
                }
              }

              textarea {
                border: 1px solid #94a3b8;
                border-radius: 10px;
                padding: 8px;
                letter-spacing: 0.025rem;

                font-size: 14px;
                font-family: "Open Sans", sans-serif;

                color: #1f2937;

                &:focus {
                  border: 1px solid #22d3ee;
                  outline: 3px solid #cffafe;
                }
              }
            }

            .form-btn {
              width: 100%;
              margin-top: 30px;

              input {
                width: 100%;
                height: 40px;
                border-radius: 8px;
                border: none;
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 0.025rem;
                background-color: #3b82f6;

                color: white;

                cursor: pointer;

                &:hover {
                  background-color: #2563eb;
                }
              }
            }
          }
        }
      }
    }

    .google-map {
      width: 100%;
      margin-top: 60px;
      background-color: white;
      border-radius: 10px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      .school-map-title {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
          font-size: 22px;
          padding: 20px 0 0 0;
        }
      }

      .school-google-map {
        width: 100%;
        display: flex;
        justify-content: center;

        padding: 10px 0 40px 0;

        iframe {
          width: 70%;
          height: 400px;

          border: none;
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .contact-us-layout-container {
    width: 100%;
    margin-top: 40px;

    .contact-us-row {
      width: 95%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      .contact-address {
        width: 90%;
        padding: 20px;
        margin-bottom: 40px;

        .contact-info {
          font-size: 20px;
        }

        .email,
        .telephone,
        .mobile {
          font-size: 14px;
        }

        .school-address {
          .address,
          .state-address,
          .pin-code {
            font-size: 14px;
          }
        }
      }

      .contact-form-container {
        width: 90%;
        padding: 20px 20px 30px 20px;

        .contact-us-form-title {
          font-size: 20px;
          margin-top: 10px;
          margin-bottom: 15px;
        }
      }
    }

    .google-map {
      width: 95%;
      margin: 40px auto;

      .school-map-title {
        h2 {
          font-size: 20px;
          padding: 10px 0 0 0;
        }
      }

      .school-google-map {
        padding: 5px 0 30px 0;

        iframe {
          width: 90%;
          height: 300px;
        }
      }
    }
  }
}
